<template>
  <div class="merite-form-expedition">
    <Container>
      <div v-if="page == 1">
        <h2 class="merite-form-expedition-title">{{ this.schema.titlepartitcipant }}</h2>
        <div class="merite-form-expedition-container">
          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textfirstname }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formfirstnameerror }"
                v-model="formfirstname"
                tabindex="1"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textemail }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formemailerror }"
                v-model="formemail"
                tabindex="3"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">Hausnummer & Stroos*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formaddress1error }"
                v-model="formaddress1"
                tabindex="5"
              />
            </div>
          </div>

          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textlastname }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formlastnameerror }"
                v-model="formlastname"
                tabindex="2"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textphone }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formphoneerror }"
                v-model="formphone"
                tabindex="4"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">Postleitzuel & Duerf*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formaddress2error }"
                v-model="formaddress2"
                tabindex="6"
              />
            </div>
          </div>
        </div>
        <div class="merite-form-expedition-container" v-show="page == 1">
          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textunits }}*</label>
              <select
                id="levelcomplete"
                name="levelcomplete"
                class="merite-form-expedition-text"
                :class="{ inputerror: formuniterror }"
                @change="onAwardUnitChange($event)"
                tabindex="7"
              >
                <option value="-">-</option>
                <option value="n-a">n/a</option>
                <option v-for="unit in this.schema.award_units" :key="unit" :value="unit.UnitName">{{ unit.UnitName }}</option>
              </select>
            </div>
          </div>
          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ $t("form-template-unitnotlisted") }}</label>
              <input type="text" class="merite-form-expedition-text" v-model="formcustomunit" tabindex="8" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="page == 2">
        <h2 class="merite-form-expedition-title">{{ this.schema.titleemergency }}</h2>
        <div class="merite-form-expedition-container">
          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textfirstname }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formemergencyfirstnameerror }"
                v-model="formemergencyfirstname"
                tabindex="7"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textemail }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formemergencyemailerror }"
                v-model="formemergencyemail"
                tabindex="9"
              />
            </div>
          </div>

          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textlastname }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formemergencylastnameerror }"
                v-model="formemergencylastname"
                tabindex="8"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textphone }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formemergencyphoneerror }"
                v-model="formemergencyphone"
                tabindex="10"
              />
            </div>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formemergencysc" />
            <p>Weideren Kontakt fir den Noutfall.</p>
          </div>
        </div>
        <div v-if="formemergencysc">
          <div class="merite-form-expedition-container">
            <div class="merite-form-expedition-flex">
              <div class="merite-form-expedition-element">
                <label class="merite-form-expedition-label">{{ this.schema.textfirstname }}*</label>
                <input
                  type="text"
                  class="merite-form-expedition-text"
                  :class="{ inputerror: formemergencyscfirstnameerror }"
                  v-model="formemergencyscfirstname"
                  tabindex="11"
                />
              </div>
              <div class="merite-form-expedition-element">
                <label class="merite-form-expedition-label">{{ this.schema.textemail }}*</label>
                <input
                  type="text"
                  class="merite-form-expedition-text"
                  :class="{ inputerror: formemergencyscemailerror }"
                  v-model="formemergencyscemail"
                  tabindex="13"
                />
              </div>
            </div>

            <div class="merite-form-expedition-flex">
              <div class="merite-form-expedition-element">
                <label class="merite-form-expedition-label">{{ this.schema.textlastname }}*</label>
                <input
                  type="text"
                  class="merite-form-expedition-text"
                  :class="{ inputerror: formemergencysclastnameerror }"
                  v-model="formemergencysclastname"
                  tabindex="12"
                />
              </div>
              <div class="merite-form-expedition-element">
                <label class="merite-form-expedition-label">{{ this.schema.textphone }}*</label>
                <input
                  type="text"
                  class="merite-form-expedition-text"
                  :class="{ inputerror: formemergencyscphoneerror }"
                  v-model="formemergencyscphone"
                  tabindex="14"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="page == 3">
        <h2 class="merite-form-expedition-title">{{ this.schema.titlemedical }}</h2>
        <div class="merite-form-expedition-container">
          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textccss }}*</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formccsserror }"
                v-model="formccss"
                tabindex="11"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textmedication }}</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formmedicalinfoerror }"
                v-model="formmedicalinfo"
                tabindex="13"
              />
            </div>
          </div>

          <div class="merite-form-expedition-flex">
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.textmedicalinfo }}</label>
              <input
                type="text"
                class="merite-form-expedition-text"
                :class="{ inputerror: formmedicationerror }"
                v-model="formmedication"
                tabindex="12"
              />
            </div>
            <div class="merite-form-expedition-element">
              <label class="merite-form-expedition-label">{{ this.schema.texttetanus }}*</label>
              <input
                type="date"
                class="merite-form-expedition-text"
                :class="{ inputerror: formtetanuserror }"
                v-model="formtetanus"
                tabindex="14"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="page == 4">
        <h2 class="merite-form-expedition-title">Aktivitéiten</h2>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formact1" :disabled="activitycounter1 >= 30" />
            <p :class="{ 'merite-form-expedition-activity-full': activitycounter1 >= 30 }">Atelier: 29.03.2025 (9h30-16h30)</p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formact2" :disabled="activitycounter2 >= 30" />
            <p :class="{ 'merite-form-expedition-activity-full': activitycounter2 >= 30 }">Atelier: 26.04.2025 (9h30-16h30)</p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formact3" :disabled="activitycounter3 >= 30" />
            <p :class="{ 'merite-form-expedition-activity-full': activitycounter3 >= 30 }">Preparatiounsexpeditioun: 17.-18.05.2025</p>
          </div>
        </div>
        <div class="merite-form-expedition-element" v-if="manualatelierdate">
          <div class="merite-form-expedition-checkbox-confirm">
            <input
              type="date"
              class="merite-form-expedition-text"
              :class="{ inputerror: formmanualatelierdateerror }"
              v-model="formmanualatelierdate"
            />
            <p class="merite-form-manual-date" :class="{ 'merite-form-expedition-activity-full': activitycounter3 >= 30 }">
              Datum vum Atelier
            </p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formact4" :disabled="activitycounter4 >= 30" />
            <p :class="{ 'merite-form-expedition-activity-full': activitycounter4 >= 30 }">Final Expeditioun: 14.-15.06.2025</p>
          </div>
        </div>
        <div class="merite-form-expedition-element" v-if="manualprepadate">
          <div class="merite-form-expedition-checkbox-confirm">
            <input
              type="date"
              class="merite-form-expedition-text"
              :class="{ inputerror: formmanualprepadateerror }"
              v-model="formmanualprepadate"
            />
            <p class="merite-form-manual-date" :class="{ 'merite-form-expedition-activity-full': activitycounter3 >= 30 }">
              Datum vun der Preparatiounsexpeditioun
            </p>
          </div>
        </div>
        <br />
      </div>

      <div v-if="page == 5">
        <h2 class="merite-form-expedition-title">{{ this.schema.textbutton }}</h2>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formconfirm1" />
            <p :class="{ confirmationerror: formconfirm1error }">
              Ech ginn heimadder dem {{ formlastname + " " + formfirstname }} d'Erlabnis un den uewe gewielten Aktivitéiten deelzehuelen. *
            </p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formconfirm2" />
            <p :class="{ confirmationerror: formconfirm2error }">
              Ech ginn heimadder de Responsabele vun den Aktivitéiten d'Erlabnis am gesondheetlechen Interessi vu mengem Kand ze handelen. *
            </p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formconfirm3" />
            <p :class="{ confirmationerror: formconfirm3error }">
              Andeems ech den/ d'{{ formlastname + " " + formfirstname }} bei dësen Aktivitéiten aschreiwen, sinn ech domadder averstanen,
              dass seng Donnéeë vun der Fondatioun Mérite Jeunesse Luxembourg gespäichert ginn an no den aktuelle Reglementatiounen zur
              Protektioun vun perséinlechen Donnéeën traitéiert ginn. *
            </p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="radio" name="photovideo" v-model="formconfirm4" value="4" />
            <p>
              Wann Dir den / d'{{ formlastname + " " + formfirstname }} fir dës Aktivitéiten umellt, sidd Dir averstanen, dass Fotoen an/
              oder Videoe vun Iech gemaach a vun der Fondatioun Mérite Jeunesse publizéiert ginn. Dës Fotoen an / oder Videoe kënne fir
              Publikatiounen oder fir d'Promotioun vum Programm op de soziale Medie benotzt ginn, grad ewéi fir geprinte Publikatiounen, vum
              Mérite Jeunesse op eng onbestëmmten Zäit genotzt ginn.
            </p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="radio" name="photovideo" v-model="formconfirm4" value="5" />
            <p>Wann Dir net domadder averstane sidd, dass Fotoen an /oder Video gemaach gi kräizt w.e.g. hei un.</p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formconfirm6" />
            <p :class="{ confirmationerror: formconfirm6error }">
              Heimadder confirméieren ech dass, den / d'{{ formlastname + " " + formfirstname }} genuch Lëtzebuergesch schwätzt a versteet
              fir un den Aktivitéiten deelzehuelen. *
            </p>
          </div>
        </div>
        <div class="merite-form-expedition-element">
          <div class="merite-form-expedition-checkbox-confirm">
            <input type="checkbox" v-model="formconfirm7" />
            <p :class="{ confirmationerror: formconfirm7error }">
              {{ this.schema.textprivacy }}
            </p>
          </div>
        </div>

        <div class="merite-form-donate-section-outer" v-show="formcomplete">
          <div class="merite-form-donate-section">
            <p class="merite-form-donate-success-text">
              {{ formerrortext }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="page == 6 && formloading == false">
        <h2 class="merite-form-expedition-title">Ënnerschreiwen</h2>
        <div class="merite-form-expedition-container-signature">
          <canvas id="signature-pad" class="signature-pad" width="800" height="400" style="width: 800px; height: 400px"></canvas>
        </div>
      </div>

      <div v-if="page == 6 && formloading == true" style="display: flex; justify-content: center; align-items: center; height: 20vh">
        <div class="lds-ring" v-if="formloading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div v-if="page == 7">
        <h2 class="merite-form-expedition-title">Erfollegreich ugemellt!</h2>
        <div class="merite-form-expedition-container">
          <p class="merite-form-expedition-text-container" style="display: flex; flex-direction: column">
            Dir sidd erfollegreich registréiert.<br />Dir kritt an den nächste Minutten eng Bestätegungsmail mat all den Detailer per Email
            gescheckt.
            <a
              id="expedition-pdf"
              class="merite-form-expedition-button"
              style="cursor: pointer; padding: 1.5em; width: fit-content; height: fit-content"
              :href="url"
              :download="filename"
            >
              PDF Dokument rooflueden
            </a>
          </p>
        </div>
      </div>

      <div class="merite-form-expedition-container">
        <div class="merite-form-expedition-flex">
          <div class="merite-form-expedition-element">
            <input type="button" class="merite-form-expedition-button" v-on:click="page--" value="Zréck" v-if="page > 1 && page != 7" />
          </div>
        </div>

        <div class="merite-form-expedition-flex">
          <div class="merite-form-expedition-element">
            <input type="button" class="merite-form-expedition-button" v-on:click="checkNextStep()" value="Weider" v-if="page < 7" />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/widgets/Container.vue";
import emailjs from "emailjs-com";
import { marked } from "marked";
import { nextTick } from "vue";

export default {
  name: "pb-formexpedition",
  components: { Container },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      levelcompleted: Object,
      multilevelcompleted: Object,
      // Page 1
      formfirstname: "",
      formlastname: "",
      formemail: "",
      formphone: "",
      formunit: "-",
      formaddress1: "",
      formaddress2: "",

      // Page 2
      formemergencyfirstname: "",
      formemergencylastname: "",
      formemergencyemail: "",
      formemergencyphone: "",
      formemergencysc: false,
      formemergencyscfirstname: "",
      formemergencysclastname: "",
      formemergencyscemail: "",
      formemergencyscphone: "",

      // Page 3
      formccss: "",
      formmedication: "",
      formmedicalinfo: "",
      formtetanus: "",

      formact1: false,
      formact2: false,
      formact3: false,
      formact4: false,

      // Page 4
      formconfirm1: false,
      formconfirm2: false,
      formconfirm3: false,
      formconfirm4: "4",
      formconfirm6: false,
      formconfirm7: false,

      formtable: "",

      // Page 1
      formfirstnameerror: false,
      formlastnameerror: false,
      formemailerror: false,
      formphoneerror: false,
      formuniterror: false,
      formaddress1error: false,
      formaddress2error: false,

      // Page 2
      formemergencyfirstnameerror: false,
      formemergencylastnameerror: false,
      formemergencyemailerror: false,
      formemergencyphoneerror: false,

      formemergencyscfirstnameerror: false,
      formemergencysclastnameerror: false,
      formemergencyscemailerror: false,
      formemergencyscphoneerror: false,

      // Page 3
      formccsserror: false,
      formmedicationerror: false,
      formmedicalinfoerror: false,
      formtetanuserror: false,

      // Page 4
      formconfirm1error: false,
      formconfirm2error: false,
      formconfirm3error: false,
      formconfirm4error: false,
      formconfirm6error: false,
      formconfirm7error: false,

      formerrortext: "",
      formcomplete: false,

      formsuccesstext: "",
      formfailedtext: "",

      formcustomunit: "",

      activitycounter1: 0,
      activitycounter2: 0,
      activitycounter3: 0,
      activitycounter4: 0,

      manualatelierdate: false,
      manualprepadate: false,

      formmanualatelierdate: "",
      formmanualprepadate: "",

      formmanualatelierdateerror: false,
      formmanualprepadateerror: false,

      formloading: false,

      page: 1,

      filename: "",
      url: "",
    };
  },
  mounted() {
    this.schema.award_units.sort(this.compare);

    this.axios
      .get("https://mdb.imagify.design/formexpeditionact1")
      .then((response) => {
        console.log("Total form submissions:", response.data.count);
        this.activitycounter1 = response.data.count;
      })
      .catch((error) => {
        console.error("Error fetching form submission count:", error);
      });

    this.axios
      .get("https://mdb.imagify.design/formexpeditionact2")
      .then((response) => {
        console.log("Total form submissions:", response.data.count);
        this.activitycounter2 = response.data.count;
      })
      .catch((error) => {
        console.error("Error fetching form submission count:", error);
      });

    this.axios
      .get("https://mdb.imagify.design/formexpeditionact3")
      .then((response) => {
        console.log("Total form submissions:", response.data.count);
        this.activitycounter3 = response.data.count;
      })
      .catch((error) => {
        console.error("Error fetching form submission count:", error);
      });

    this.axios
      .get("https://mdb.imagify.design/formexpeditionact4")
      .then((response) => {
        console.log("Total form submissions:", response.data.count);
        this.activitycounter4 = response.data.count;
      })
      .catch((error) => {
        console.error("Error fetching form submission count:", error);
      });
  },
  updated() {},
  methods: {
    generateText() {
      return marked(this.schema.textprivacy);
    },
    compare(a, b) {
      if (a.UnitName < b.UnitName) {
        return -1;
      }
      if (a.UnitName > b.UnitName) {
        return 1;
      }
      return 0;
    },
    onAwardUnitChange(e) {
      this.formunit = e.target.value;
    },

    onVeganChange(e) {
      this.formvegan = e.target.value;
    },
    onLunchChange(e) {
      this.formlunch = e.target.value;
    },
    checkNextStep() {
      if (this.page === 1) {
        this.formfirstnameerror = !this.formfirstname;
        this.formlastnameerror = !this.formlastname;
        this.formemailerror = !this.formemail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formemail);
        this.formphoneerror = !this.formphone || !/^[\d\s()+-]+$/.test(this.formphone);
        this.formuniterror = !this.formunit;
        this.formaddress1error = !this.formaddress1;
        this.formaddress2error = !this.formaddress2;

        if (
          !this.formfirstnameerror &&
          !this.formlastnameerror &&
          !this.formemailerror &&
          !this.formphoneerror &&
          !this.formuniterror &&
          !this.formaddress1error &&
          !this.formaddress2error
        ) {
          this.page++;
        } else {
          this.formerrortext = "All fields have to be filled.";
        }
      } else if (this.page === 2) {
        this.formemergencyfirstnameerror = !this.formemergencyfirstname;
        this.formemergencylastnameerror = !this.formemergencylastname;
        this.formemergencyemailerror = !this.formemergencyemail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formemergencyemail);
        this.formemergencyphoneerror = !this.formemergencyphone || !/^[\d\s()+-]+$/.test(this.formemergencyphone);

        if (
          !this.formemergencyfirstnameerror &&
          !this.formemergencylastnameerror &&
          !this.formemergencyemailerror &&
          !this.formemergencyphoneerror
        ) {
          if (this.formemergencyemail == this.formemail) {
            this.formemergencyemailerror = true;
          }
          if (this.formemergencyphone == this.formphone) {
            this.formemergencyphoneerror = true;
          }

          if (!this.formemergencyemailerror && !this.formemergencyphoneerror) {
            if (this.formemergencysc) {
              this.formemergencyscfirstnameerror = !this.formemergencyscfirstname;
              this.formemergencysclastnameerror = !this.formemergencysclastname;
              this.formemergencyscemailerror = !this.formemergencyscemail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formemergencyscemail);
              this.formemergencyscphoneerror = !this.formemergencyscphone || !/^[\d\s()+-]+$/.test(this.formemergencyscphone);

              if (this.formemergencyemail == this.formemergencyscemail) {
                this.formemergencyscemailerror = true;
              }
              if (this.formemergencyphone == this.formemergencyscphone) {
                this.formemergencyscphoneerror = true;
              }

              if (
                !this.formemergencyscfirstnameerror &&
                !this.formemergencysclastnameerror &&
                !this.formemergencyscemailerror &&
                !this.formemergencyscphoneerror
              ) {
                this.page++;
              } else {
                this.formerrortext = "All fields have to be filled.";
              }
            } else {
              this.page++;
            }
          }
        } else {
          this.formerrortext = "All fields have to be filled.";
        }
      } else if (this.page === 3) {
        this.formccsserror = !this.formccss || !/^\d{13}$/.test(this.formccss);
        this.formmedicationerror = false;
        this.formmedicalinfoerror = false;
        this.formtetanuserror = !this.formtetanus;

        if (!this.formccsserror && !this.formmedicationerror && !this.formmedicalinfoerror && !this.formtetanuserror) {
          this.page++;
        } else {
          this.formerrortext = "All fields have to be filled.";
        }
      } else if (this.page == 4) {
        if (this.manualatelierdate && this.formmanualatelierdate == "") {
          this.formmanualatelierdateerror = true;
          return;
        } else {
          this.formmanualatelierdateerror = false;
        }

        if (this.manualprepadate && this.formmanualprepadate == "") {
          this.formmanualprepadateerror = true;
          return;
        } else {
          this.formmanualprepadateerror = false;
        }

        if (this.formact1 || this.formact2 || this.formact3 || this.formact4) {
          this.page++;
        } else {
          this.formerrortext = "All fields have to be filled.";
        }
      } else if (this.page === 5) {
        console.log(this.formconfirm4);

        this.formconfirm1error = !this.formconfirm1;
        this.formconfirm2error = !this.formconfirm2;
        this.formconfirm3error = !this.formconfirm3;
        this.formconfirm6error = !this.formconfirm6;
        this.formconfirm7error = !this.formconfirm7;

        if (
          !this.formconfirm1error &&
          !this.formconfirm2error &&
          !this.formconfirm3error &&
          !this.formconfirm6error &&
          !this.formconfirm7error
        ) {
          this.page++;
        } else {
          this.formerrortext = "All fields have to be filled.";
        }
      } else if (this.page === 6) {
        this.submitSignatureAndData();
      }
    },
    submitData() {
      let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");

      var forminputerror = false;

      this.formfirstnameerror = false;
      this.formlastnameerror = false;
      this.formemailerror = false;
      this.formuniterror = false;

      if (this.formfirstname == "") {
        forminputerror = true;
        this.formfirstnameerror = true;
      }

      if (this.formlastname == "") {
        forminputerror = true;
        this.formlastnameerror = true;
      }

      if (this.formemail == "") {
        forminputerror = true;
        this.formemailerror = true;
      }

      if (this.formphone == "") {
        forminputerror = true;
        this.formphoneerror = true;
      }

      if (this.formunit == "" || this.formunit == "-") {
        forminputerror = true;
        this.formuniterror = true;
      }

      console.log("confirm");
      console.log(this.schema.textconfirm);

      if (forminputerror == false) {
        let that = this;

        let comp = this.formunit + "-" + this.formvegan + "-" + this.formintolerance;

        this.axios
          .post("https://mdb.imagify.design/formexpedition", {
            table: that.schema.dbtablename,
            firstname: that.formfirstname,
            lastname: that.formlastname,
            email: that.formemail,
            phone: that.formphone,
            unit: that.formunit,
            unitcustom: that.formcustomunit,
            lunch: that.formlunch,
            vegan: that.formvegan,
            intolerance: that.formintolerance,
          })
          .then(function (response) {
            that.formcomplete = true;

            const payload = {
              email: that.formemail,
              emailcontent: that.schema.textconfirm,
            };

            emailjs.send("service_ixwgcl8", "template_frnlzqj", payload, "hZu8PletaDsGb3ktM");

            if (locale == "en") {
              that.formerrortext = "You are registered successfully for the event.";
            } else if (locale == "fr") {
              that.formerrortext = "Vous êtes inscrit avec succès à l'événement.";
            } else if (locale == "lu") {
              that.formerrortext = "Dir sidd erfollegreich fir d'Veranstaltung registréiert.";
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        if (locale == "en") {
          this.formerrortext = "All fields have to be filled.";
        } else if (locale == "fr") {
          this.formerrortext = "Tous les champs doivent être remplis.";
        } else if (locale == "lu") {
          this.formerrortext = "All felder mussen ausgefëllt ginn.";
        }
      }
    },
    submitSignatureAndData() {
      const canvas = document.getElementById("signature-pad");
      const ctx = canvas.getContext("2d");

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let minX = canvas.width,
        minY = canvas.height,
        maxX = 0,
        maxY = 0;

      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const index = (y * canvas.width + x) * 4;
          const r = data[index];
          const g = data[index + 1];
          const b = data[index + 2];
          const a = data[index + 3];

          if (r === 0 && g === 0 && b === 0 && a !== 0) {
            if (x < minX) minX = x;
            if (y < minY) minY = y;
            if (x > maxX) maxX = x;
            if (y > maxY) maxY = y;
          }
        }
      }

      const width = maxX - minX;
      const height = maxY - minY;

      if (width < 2 || height < 2) {
        alert("Please sign the form.");
        return;
      }
      const cropped = ctx.getImageData(minX, minY, width, height);

      const newCanvas = document.createElement("canvas");
      newCanvas.width = width;
      newCanvas.height = height;
      const newCtx = newCanvas.getContext("2d");
      newCtx.putImageData(cropped, 0, 0);

      const signature = newCanvas.toDataURL();

      //https://mdb.imagify.design/formexpedition
      this.formloading = true;
      this.axios
        .post(
          "https://mdb.imagify.design/formexpedition",
          {
            formfirstname: this.formfirstname,
            formlastname: this.formlastname,
            formemail: this.formemail,
            formphone: this.formphone,
            formunit: this.formunit,
            formaddress1: this.formaddress1,
            formaddress2: this.formaddress2,
            formcustomunit: this.formcustomunit,
            formemergencyfirstname: this.formemergencyfirstname,
            formemergencylastname: this.formemergencylastname,
            formemergencyemail: this.formemergencyemail,
            formemergencyphone: this.formemergencyphone,
            formemergencysc: this.formemergencysc,
            formemergencyscfirstname: this.formemergencyscfirstname,
            formemergencysclastname: this.formemergencysclastname,
            formemergencyscemail: this.formemergencyscemail,
            formemergencyscphone: this.formemergencyscphone,
            formccss: this.formccss,
            formmedication: this.formmedication,
            formmedicalinfo: this.formmedicalinfo,
            formtetanus: this.formtetanus,
            formact1: this.formact1,
            formact2: this.formact2,
            formact3: this.formact3,
            formact4: this.formact4,
            formconfirm1: this.formconfirm1,
            formconfirm2: this.formconfirm2,
            formconfirm3: this.formconfirm3,
            formconfirm4: this.formconfirm4,
            formconfirm6: this.formconfirm6,
            manualatelierdate: this.manualatelierdate,
            manualprepadate: this.manualprepadate,
            formmanualatelierdate: this.formmanualatelierdate,
            formmanualprepadate: this.formmanualprepadate,
            signature: signature,
          },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => {
          /*
          console.log("Form data submitted successfully:", response);
          this.formsuccesstext = response.data;
          this.page++;
          */

          const url = window.URL.createObjectURL(new Blob([response.data]));

          this.filename = "merite_expedition_" + this.formccss + ".pdf";
          this.url = url;
          this.formloading = false;
          this.page++;
        })
        .catch((error) => {
          console.error("Error submitting form data:", error);
        });
    },
  },
  watch: {
    page: async function (val) {
      if (val == 6) {
        await nextTick();

        let canvas = document.getElementById("signature-pad");
        const ctx = canvas.getContext("2d");
        ctx.scale(1, 1);

        let drawing = false;
        let x = 0;
        let y = 0;

        canvas.addEventListener("mousedown", (e) => {
          x = e.offsetX;
          y = e.offsetY;
          drawing = true;
        });

        canvas.addEventListener("mousemove", (e) => {
          if (drawing === true) {
            drawLine(ctx, x, y, e.offsetX, e.offsetY);
            x = e.offsetX;
            y = e.offsetY;
          }
        });

        window.addEventListener("mouseup", (e) => {
          if (drawing === true) {
            drawLine(ctx, x, y, e.offsetX, e.offsetY);
            x = 0;
            y = 0;
            drawing = false;
          }
        });

        function drawLine(context, x1, y1, x2, y2) {
          context.beginPath();
          context.lineCap = "round";
          context.strokeStyle = "black";
          context.lineWidth = 2;
          context.moveTo(x1, y1);
          context.lineTo(x2, y2);
          context.stroke();
          context.closePath();
        }
      }
    },
    formact1: function (val) {
      if (val) {
        this.manualatelierdate = false;
        if (this.formact2 == true) {
          this.formact2 = false;
        }
      } else {
        if (this.formact1 == false && this.formact2 == false && this.formact3 == true) {
          this.manualatelierdate = true;
        } else {
          this.manualatelierdate = false;
        }
      }
    },
    formact2: function (val) {
      if (val) {
        this.manualatelierdate = false;
        if (this.formact1 == true) {
          this.formact1 = false;
        }
      } else {
        if (this.formact1 == false && this.formact2 == false && this.formact3 == true) {
          this.manualatelierdate = true;
        } else {
          this.manualatelierdate = false;
        }
      }
    },
    formact3: function (val) {
      if (val) {
        this.manualprepadate = false;
        if (this.formact1 == false && this.formact2 == false) {
          this.manualatelierdate = true;
        } else {
          this.manualatelierdate = false;
        }
      } else {
        this.manualatelierdate = false;
        if (this.formact4) {
          this.manualprepadate = true;
        } else {
          this.manualprepadate = false;
        }
      }
    },
    formact4: function (val) {
      if (val) {
        if (this.formact3 == false) {
          this.manualprepadate = true;
        } else {
          this.manualprepadate = false;
        }
      } else {
        this.manualprepadate = false;
      }
    },
  },
};
</script>

<style>
.merite-form-expedition {
  padding: 0 0 4em 0;
}

.merite-form-expedition-container {
  display: flex;
}

.merite-form-expedition-flex {
  flex: 1;
}

.merite-form-expedition-flex:nth-child(1) {
  padding: 0 1em 0 0;
}

.merite-form-expedition-flex:nth-child(2) {
  padding: 0 0 0 1em;
}

.merite-form-expedition-element {
  display: flex;
  flex-direction: column;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  align-items: flex-start;
}

.merite-form-expedition-text {
  border: none;
  border-bottom: 2px solid grey;
  background-color: transparent;

  height: 2em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-expedition-button {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  border: none;
  background-color: var(--charte-color-benevolat);

  color: white;

  height: 3em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-expedition-container.spacer {
  margin: 4em 0 0 0;
}

.merite-form-expedition-checkbox {
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;

  height: 2em;
  margin: 0 0 4em 0;
}

.merite-form-expedition-text:focus {
  outline: none;
}

.inputerror {
  border-bottom: 2px solid red !important;
}

.merite-form-donate-section-outer {
  display: flex;

  align-items: center;
  align-content: center;
  justify-content: center;
}

.merite-form-donate-section {
  width: 50%;
}

.merite-form-donate-success-text {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-form-expedition-text-container {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
  padding-bottom: 1em;
}

.merite-supportus-box-flex {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-form-expedition-title {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 24pt;
}

.merite-form-expedition-checkbox-confirm {
  display: flex;
  flex-direction: row;
}

.merite-form-expedition-checkbox-confirm p {
  padding-left: 1em;
}

.confirmationerror {
  font-weight: 500;
}

#signature-pad {
  border: 1px solid black;
  border-radius: 0.75em;
}

.merite-form-expedition-container-signature {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1em 0 4em 0;
}

.merite-form-expedition-activity-full {
  text-decoration: line-through;
}

.merite-form-manual-date {
  width: 25vw;
  padding-left: 2em;
}

@media only screen and (max-width: 600px) {
  .merite-form-expedition-container {
    flex-direction: column;
  }

  .merite-form-expedition-flex:nth-child(1) {
    padding: 0;
  }

  .merite-form-expedition-flex:nth-child(2) {
    padding: 0;
  }
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
